import { onPageReady } from '../../core/js/on-page-ready.js';

onPageReady().then(async () => {
  const { hydrateReactRootComponent } = await import(
    '../../core/js/hydrate-react.js'
  );
  const { FrontPage } = await import(
    '../../../src/aftenposten/views-react/layouts/frontpage/FrontPage.js'
  );

  await hydrateReactRootComponent(FrontPage);
});
